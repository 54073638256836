.product-summary {
	display: flex;
	flex-direction: column;
	text-align: justify;
	padding-left: 10px;
	padding-right: 10px;
}

.pill {
	display: inline-block;
	padding: 0px 5px;
	border-radius: 10px;
	background-color: lightgray;
	text-align: center;  
}

.pill-unknown {
	padding: 5px 10px;
	border-radius: 20px;
}

.pill-low {
	padding: 5px 10px;
	border-radius: 20px;
	color: black;
	background-color: lightblue;
}

.pill-medium {
	padding: 5px 10px;
	border-radius: 20px;
	color: black;
	background-color: yellow;
}


.pill-high {
	padding: 5px 10px;
	border-radius: 20px;
	color: white;
	background-color: darkred;
}

.similarity-header {
	margin-top: 1em;
	color: #2393d8;
	font-size: 12px;
	font-weight: bold;
	/* text-decoration: underline; */
}