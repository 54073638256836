.claim-ready {
    margin-left: 5px;
    font-size: 11px;
    color: gray;
    cursor: pointer;
}

.claim-not-ready {
    margin-left: 5px;
    font-size: 11px;
    color: lightgray;
}