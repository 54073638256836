.float-right {
	text-align: right
}

@import "@radix-ui/colors/black-alpha.css";
@import "@radix-ui/colors/mauve.css";
@import "@radix-ui/colors/violet.css";

/* reset */
button,
h3 {
	all: unset;
}

.AccordionRoot {
	border-radius: 6px;
	width: 1150px;
	box-shadow: 0 2px 10px var(--black-a4);
	font-family: Aleo, Arial, sans-serif;
    font-weight: 500;
	font-size: 12px;
	/* background-color: white; */
	margin-top: 70px;
	color: grey;
}

.AccordionItem {
	overflow: hidden;
	margin-top: 10px;
}

.AccordionItem:first-child {
	margin-top: 0;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.AccordionItem:last-child {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

.AccordionItem:focus-within {
	position: relative;
	z-index: 1;
	box-shadow: 0 0 0 2px var(--mauve-12);
}

.AccordionHeader {
	display: flex;
}

.AccordionTrigger {
	background-color: transparent;
	padding: 0 20px;
	height: 45px;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 15px;
	line-height: 1;
	box-shadow: 0 1px 0 var(--mauve-6);
	font-family: Aleo, Arial, sans-serif;
    font-weight: 500;
	color: #2393d8;
}

.AccordionTrigger:hover {
	background-color: var(--mauve-2);
}

.AccordionContent {
	overflow: hidden;
}
.AccordionContent[data-state="open"] {
	animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionContent[data-state="closed"] {
	animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionContentText {
	padding: 15px 20px;
}

.AccordionChevron {
	color: var(--violet-10);
	transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionTrigger[data-state="open"] > .AccordionChevron {
	transform: rotate(180deg);
}

@keyframes slideDown {
	from {
		height: 0;
	}
	to {
		height: var(--radix-accordion-content-height);
	}
}

@keyframes slideUp {
	from {
		height: var(--radix-accordion-content-height);
	}
	to {
		height: 0;
	}
}

.vendor-name {
	text-decoration: underline;
}